html, * {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}
.container {
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
  width: 100%;
}
.btn {
  cursor: pointer;
}

@import "header.scss";
@import "footer.scss";

.hero {
  background: url('../img/hero-bg.png') no-repeat center / cover;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 200px 0;
  text-align: center;
  &__title {
    color: var(--basic-color-0, #FFF);
    text-align: center;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px; /* 109.375% */
    margin: 0;
  }
  &__text {
    color: var(--basic-color-0, #FFF);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin: 0;
  }
  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    max-width: 970px;
    padding: 0 15px;
  }
}
.container--assimetric {
  max-width: 1800px;
}
.main-info {
  padding: 96px 0;
  &__row {
    width: 1466px;
    display: flex;
    align-items: center;
    gap: 60px;
  }
  &__img {
    filter: drop-shadow(16px 16px 32px rgba(0, 0, 0, 0.25));
    border-radius: 15px;
    width: 775px;
  }
  &__content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
  }
  &__title {
    color: var(--basic-color-5, #111);
    margin: 0;
  }
}

.more-info {
  background: var(--basic-color-1, #F1F1F4);
  padding: 96px 0;
  &__container {
    max-width: 1105px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    text-align: center;
  }
  &__title {
    margin: 0;
    max-width: 770px;
    text-align: center;
  }
  &__img {
    max-width: 100%;
    border-radius: 10px;
    filter: drop-shadow(16px 16px 32px rgba(0, 0, 0, 0.25));
  }
  &__text {
    margin-top: 6px;
    max-width: 770px;
  }
}

.about {
  padding: 96px 0;

  &__row {
    display: flex;
    align-items: center;
    gap: 30px;
  }
  &__img {
    width: 470px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
  &__title {
    margin: 0;
  }
}
.videos {
  padding-top: 32px;
  &__list {
    margin: 37px 0 96px;
  }
}
.filter {
  padding: 24px;
  border-radius: 8px;
  background: var(--bg-color-gray, #FAFAFA);

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  &__title {
    color: var(--basic-color-5, #111);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
  }
  &__total {
    color: var(--basic-color-4, #7E7E7E);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;

    span {
      color: var(--basic-color-5, #111);
    }
  }
  &__body {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(5, 1fr);

    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__label {
    display: flex;
    flex-direction: column;
    color: var(--basic-color-4, #7E7E7E);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    gap: 4px;

    select {
      color: var(--basic-color-4, #7E7E7E);
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      padding: 8px 16px;
      border-radius: 100px;
      outline: none;
    }
  }
}

/* Сховати початковий <select> */
.select {
  display: none;
}

/* Створити стилізований кастомний селект */
.custom-select {
  position: relative;
  color: var(--basic-color-4, #7E7E7E);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0;
  border-radius: 100px;
  outline: none;
  z-index: 2;
}

.select-header {
  border-radius: 100px;
  border: 1px solid var(--basic-color-2, #DFDFDF);
  background: var(--basic-color-0, #FFF);
  display: flex;
  padding: 8px 32px 8px 16px;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  transition: background-color .3s;
  &::after {
    content: '';
    position: absolute;
    right: 16px;
    top: 13px;
    width: 16px;
    height: 16px;
    transition: .5s;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='%23000' fill-opacity='.85' d='M8.005 13c.23 0 .46-.094.617-.272l7.13-7.446A.845.845 0 0 0 16 4.68c0-.489-.36-.864-.838-.864a.866.866 0 0 0-.599.244l-7.056 7.352h.986L1.437 4.061a.833.833 0 0 0-.599-.244C.36 3.817 0 4.192 0 4.68c0 .234.092.441.249.61l7.13 7.437a.857.857 0 0 0 .626.272Z'/%3E%3C/svg%3E") no-repeat center / 100% 100%;
  }
  &.opened::after {
    transform: scaleY(-1);
  }
}

.options-list {
  list-style: none;
  padding: 20px 0 0;
  margin: -20px 0 0;
  border: 1px solid #ccc;
  max-height: 0;
  opacity: 0;
  overflow-y: auto;
  transition: .3s;
  background-color: #fff;
  z-index: -1;
  position: relative;

  position: absolute;
  width: 100%;
  &.opened {
    max-height: 110px;
    opacity: 1;
  }
}

.options-list li {
  padding: 8px;
  cursor: pointer;
}

.options-list li:hover {
  background-color: #f0f0f0;
}
.videos-list {
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;

  @media (max-width: 1200px){
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 900px){
    grid-template-columns: repeat(2, 1fr);
  }

  &__item {
    border-radius: 8px;
    overflow: hidden;
    &:hover {
      .videos-list-item__link {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}
.videos-list-item {
  background-size: cover;
  background-position: center;
  &__link {
    color: #fff;
    text-decoration: none;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    background: var(--basic-color-5, #111);

    opacity: 0;
    pointer-events: none;
    transition: .3s;
  }
  &__name {
    color: var(--basic-color-0, #FFF);
    /* font-family: Poppins; */
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }
  &__points {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    list-style: none;
  }
  &__point {
    border-radius: 100px;
    background: #333;
    padding: 0 8px;
    color: var(--basic-color-1, #F1F1F4);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__description {
    overflow: hidden;
    color: var(--basic-color-3, #999);
    text-overflow: ellipsis;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 0;
  }
  &__add {
    overflow: hidden;
    color: var(--basic-color-2, #DFDFDF);
    text-overflow: ellipsis;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    display: flex;
    align-items: center;
  }
  &__fav {
    margin-left: 8px;
    padding: 0;
    width: 24px;
    height: 24px;
    background: transparent;
    border: none;
    cursor: pointer;

    &:hover,
    &.active {
      path {
        fill: red;
      }
    }
  }
}
.video {
  padding-top: 32px;
  padding-bottom: 96px;

  &__row {
    display: flex;
    gap: 30px;
    max-width: 990px;
    margin: auto;
  }
  &__sidebar {
    width: 270px;
    display: flex;
    flex-direction: column;

    img {
      height: 410px;
      object-fit: cover;
      border-radius: 10px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
  }
  &__btns {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 0;
  }
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      path {
        fill: red;
      }
    }

    svg {
      width: 24px;
      margin-left: 10px;
    }
    path {
      fill: #111111;
      transition: .3s;
    }
  }
  &__name {
    margin: 0 0 6px;
    color: var(--basic-color-5, #111);
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }
  &__description p {
    overflow: hidden;
    color: var(--basic-color-4, #7E7E7E);
    text-overflow: ellipsis;
    whitespace: nowrap;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  &__main {
    margin: 24px 0;
  }
}
.video-main {
  &__row {
    overflow: hidden;
    color: var(--basic-color-5, #111);
    text-overflow: ellipsis;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  &__link {
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }
}

.video-table {
  &__tabs {
    border-radius: 4px;
    background: var(--basic-color-1, #F1F1F4);
    display: flex;
    width: fit-content;
    border: 1px solid var(--basic-color-1, #F1F1F4);
    margin-bottom: 18px;
  }
  &__tab {
    outline: none;
    border-radius: 4px;
    background: transparent;
    /* background: var(--basic-color-0, #FFF); */
    padding: 14px 16px;
    border: none;
    color: var(--basic-color-5, #111);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
    transition: color .3s, background-position .3s;
    &:not(.active):hover {
      color: var(--primary-color-pink, #FE4DCD);
    }
    &.active {
      background: var(--basic-color-0, #FFF);
      font-weight: 600;
    }
  }
}
.video-future {
  border-radius: 4px;
  background: var(--bg-color-gray, #FAFAFA);
  padding: 8px 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  align-items: flex-start;
  &__title {
    margin: 0;
    color: var(--basic-color-5, #111);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  &__text {
    margin: 0;
    color: var(--basic-color-4, #7E7E7E);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
.season-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.season {
  display: none;

  &.activetab {
    display: block;
  }
}
.season-list-item {
  background: var(--basic-color-0, #FFF);
  padding: 8px 0;
  display: flex;
  border-bottom: 1px solid #F1F1F4;
  &__number {
    color: var(--basic-color-5, #111);
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-right: 16px;
  }
  &__name {
    background: transparent;
    border: none;
    color: var(--primary-color-pink, #FE4DCD);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration-line: underline;
    padding: 0;
    cursor: pointer;
    margin-bottom: 4px;
    &:hover {
      text-decoration: none;
    }
  }
  &__description {
    transition: .3s;
    max-height: 0;
    overflow: hidden;
    &.opened {
      max-height: 300px;
    }
    p {
      font-size: 13px;
      line-height: 1.2;
    }
  }
  &__more {
    background: transparent;
    border: none;
    padding: 0;
    color: var(--basic-color-4, #7E7E7E);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    margin-top: 4px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  &__info {
    text-align: right;
    margin-left: auto;
    max-width: 300px;
    //width: 100%;
    min-width: 180px;
  }
  &__flags {
    display: flex;
    gap: 8px;
    margin-bottom: 4px;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    img {
      border: 1px solid var(--basic-color-2, #DFDFDF);
      width: 24px;
      height: 24px;
      border-radius: 4px;
      overflow: hidden;
    }
  }
  &__update {
    color: var(--basic-color-4, #7E7E7E);
    text-align: right;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
  }
}

.terms, .faq {
  padding: 32px 0 48px;
  &__content {
    max-width: 570px;
    margin-left: auto;
    margin-right: auto;
    color: var(--basic-color-5, #111);

    h1 {
      margin: 0 0 16px;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
    }

    h2 {
      margin-top: 16px;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 33px;
    }
    p, li {
      line-height: 24px; /* 150% */
    }

    a {
      color: inherit;
      font-weight: 600;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.faq {
  padding-top: 64px;
  padding-bottom: 110px;
  &__items {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
.faq-item {
  border-radius: 8px;
  background: var(--bg-color-gray, #FAFAFA);
  overflow: hidden;
  &.opened {
    .faq-item__body {
      padding-top: 8px;
      padding-bottom: 8px;
      max-height: 600px;
    }
    .faq-item__header::after {
      transform: scaleY(-1);
    }
  }
  &__header {
    color: var(--basic-color-5, #111);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 8px 40px 8px 24px;
    position: relative;
    cursor: pointer;
    transition: .3s;
    &:hover {
      background: #FFF5FC;
    }

    &::after {
      content: '';
      position: absolute;
      right: 24px;
      top: 12px;
      width: 16px;
      height: 16px;
      transition: .5s;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='%23000' fill-opacity='.85' d='M8.005 13c.23 0 .46-.094.617-.272l7.13-7.446A.845.845 0 0 0 16 4.68c0-.489-.36-.864-.838-.864a.866.866 0 0 0-.599.244l-7.056 7.352h.986L1.437 4.061a.833.833 0 0 0-.599-.244C.36 3.817 0 4.192 0 4.68c0 .234.092.441.249.61l7.13 7.437a.857.857 0 0 0 .626.272Z'/%3E%3C/svg%3E") no-repeat center / 100% 100%;
    }
  }
  &__body {
    padding: 0 24px;
    color: var(--basic-color-4, #7E7E7E);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    transition: .3s;
    max-height: 0;
    overflow: hidden;
  }
}
.dictionary {
  padding: 34px 0 96px;
}
.dictionary-main {
  display: flex;
  align-items: flex-start;
  gap: 30px;
}
.sidebar {
  width: 270px;
  &__name {
    color: var(--basic-color-5, #111);

    /* H3 - bold */
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 112.5% */
    margin-top: 0;
    margin-bottom: 32px;
  }
  &__btn {
    width: 100%;
    margin-bottom: 24px;
  }
}
.sidebar-check {
  margin-bottom: 24px;

  input {
    display: none;
    &:checked + .sidebar-checkbox__label {
      &::after {
        content: '';
        width: 23px;
        height: 23px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgPHBhdGggc3Ryb2tlPSIjRkU0RENEIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgZD0ibTE2IDktNS41IDVMOCAxMS43MjgiLz4KPC9zdmc+Cg==") no-repeat center / 100% 100%;
      }
    }
  }
}
.sidebar-checkbox {
  &__label {
    color: var(--basic-color-4, #7E7E7E);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    padding-left: 32px;
    position: relative;
    cursor: pointer;

    &::before {
      content: '';
      width: 23px;
      height: 23px;
      border-radius: 3px;
      border: 1px solid var(--basic-color-4, #7E7E7E);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.sidebar-block {
  border-radius: 8px;
  background: var(--bg-color-gray, #FAFAFA);
  padding: 24px 16px;
  margin-bottom: 24px;

  &__title {
    margin: 0 0 8px;
    color: var(--basic-color-5, #111);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  &__items {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  &__item {
    display: flex;
    align-items: center;
    input {
      display: none;

      &:checked + .sidebar-block__label {
        color: var(--primary-color-pink, #FE4DCD);
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid #E8E8E8;
    }
  }
  &__label {
    padding: 8px;
    display: block;
    cursor: pointer;
  }
  &__count {
    color: var(--basic-color-4, #7E7E7E);
    text-align: right;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    margin-left: auto;
  }
}
.dictionary-search {
  margin-right: 32px;
  &__input {
    border-radius: 100px;
    border: 1px solid var(--basic-color-4, #7E7E7E);
    background: var(--basic-color-0, #FFF);
    padding: 12px 16px 12px 58px;
    min-width: 300px;
    outline: none;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 32 32'%3E%3Cpath fill='%23111' d='m28.53 27.47-6.422-6.422a10.769 10.769 0 1 0-1.06 1.06l6.422 6.422a.75.75 0 0 0 1.06-1.06ZM4.75 14A9.25 9.25 0 1 1 14 23.25 9.261 9.261 0 0 1 4.75 14Z'/%3E%3C/svg%3E") no-repeat 16px 7px / 31px 31px;

    &::placeholder {
      color: var(--basic-color-4, #7E7E7E);

      /* Body - regular */
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }
}
.dictionary-section {
  width: 870px;
  &__head {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 48px;
    margin-bottom: 18px;
  }
  &__item {
    &--type {
      display: flex;
      align-items: center;
      border-radius: 4px;
      background: var(--basic-color-1, #F1F1F4);
      padding: 2px;
      gap: 8px
    }
  }
}
.dictionary-type {
  input {
    display: none;

    &:checked + .dictionary-type__label {
      border-radius: 4px;
      background: var(--basic-color-0, #FFF);
    }
  }
  &__label {
    display: flex;
    align-items: center;
    padding: 8px 22px;
    transition: .3s;
    cursor: pointer;
    svg {
      width: 32px;
    }
  }
}
button.btn {
  &--icon {
    border-radius: 8px;
    background: var(--bg-color-gray, #FAFAFA);
    border: none;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    color: var(--basic-color-5, #1A1A1A);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding: 8px 16px;
    width: 100%;

    svg {
      width: 24px;
      margin-right: 8px;
    }
  }
}
.dictionary-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;

  &--listing {
    grid-template-columns: 1fr;
    gap: 8px;

    .dictionary-item__languages {
      display: none;
    }
    .dictionary-item__translation {
      color: var(--basic-color-5, #111);

      /* Body - regular */
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
    .dictionary-item__translation,
    .dictionary-item__header {
      margin-bottom: 0;
    }
    .dictionary-item {
      padding: 16px 32px;
    }
    .dictionary-item__header {
      gap: 8px;
    }
  }
}
.dictionary-item {
  border-radius: 8px;
  border: 1px solid var(--basic-color-2, #DFDFDF);
  background: var(--basic-color-0, #FFF);
  padding: 16px 32px 24px;
  transition: .3s;
  &.hidden {
    padding-bottom: 16px;
  }

  &:hover {
    border: 1px solid var(--basic-color-3, #999);
  }
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    &.hidden {
      margin-bottom: 0;
    }
  }
  &__value {
    color: var(--primary-color-pink, #FE4DCD);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    transition: .3s;

    &:hover {
      opacity: .8;
    }
  }
  &__btns {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 6px;
  }
  &__translation {
    margin-bottom: 8px;
    color: var(--basic-color-5, #111);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &.hidden {
      display: none;
    }
  }
  &__languages {
    color: var(--basic-color-4, #7E7E7E);
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    &.hidden {
      display: none;
    }
  }
}
.btn-icon {
  background: transparent;
  border: none;
  padding: 0;
  width: 24px;
  min-width: 24px;
  display: flex;
  cursor: pointer;
  flex-direction: column;

  path {
    transition: .3s;
  }
  &:hover {
    path {
      fill: #111;
      opacity: 1;
    }
  }
}
.notifications {
  padding: 64px 0 96px;
}
.notifications-block {
  max-width: 370px;
  margin: auto auto 32px;
  &__title {
    color: var(--basic-color-5, #111);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    margin: 0 0 16px;
  }
  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }
}
.notifications-block-item {
  &__name {
    color: var(--basic-color-5, #111);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
.custom-checkbox {
  display: none;
  &__label {
    display: flex;
    padding: 7px 0;
    align-items: center;
    border-bottom: 1px solid var(--basic-color-3, #999);
    min-height: 70px;
    margin-right: 20px;
  }
  &__wrapper {
    position: relative;
    width: 51px;
    min-width: 51px;
    height: 31px;
    background-color: var(--basic-color-2, #DFDFDF);
    border-radius: 999px;
    cursor: pointer;
    pointer-events: all;
    margin-left: auto;
    transition: .3s;
  }
  &__selector {
    filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.06)) drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.15));
    background: #fff;
    width: 27px;
    height: 27px;
    display: block;
    border-radius: 50%;
    top: 2px;
    position: absolute;
    left: 2px;
    transition: .3s;
  }
  &:checked + .custom-checkbox__wrapper {
    background: #C7349D;

    .custom-checkbox__selector {
      left: 22px;
    }
  }
}
.subscriptions {
  padding: 96px 0;
}
.subscriptions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
}

.subscriptions-list-item {
  &__link {
    border-radius: 4px;
    border: 1px solid var(--basic-color-2, #DFDFDF);
    background: var(--basic-color-0, #FFF);
    box-shadow: 0px -4px 0px 0px #4D4D4D;
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: inherit;
    min-height: 404px;
    transition: .3s;

    &:hover {
      box-shadow: 0px -4px 0px 0px #FE4DCD;
      .btn {
        background: #111;
        color: #fff;
      }
    }

    &--active {
      box-shadow: 0px -4px 0px 0px #999;
      pointer-events: none;
    }
  }
  &__period {
    color: var(--basic-color-5, #111);
    text-align: center;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 16px;
    line-height: 33px;
  }
  &__price {
    margin: 0 0 16px;
    color: var(--basic-color-5, #111);
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }
  &__discount {
    display: block;
    color: var(--basic-color-3, #999);
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
  }
  &__list {
    padding: 0;
    list-style: none;
    margin: 0 0 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__point {
    color: var(--basic-color-5, #111);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding-left: 24px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 2px;
      width: 16px;
      height: 16px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='%23111' d='m14.265 4.765-8 8a.375.375 0 0 1-.53 0l-3.5-3.5a.375.375 0 0 1 .53-.53L6 11.969l7.735-7.734a.375.375 0 0 1 .53.53Z'/%3E%3C/svg%3E") no-repeat center / 100% 100%;
    }
  }
  .btn {
    text-align: center;
  }
}
.subscriptions-list-item-active {
  background: var(--bg-color-pink, #FFF5FC);
  padding: 21px 26px 10px;
  margin-top: -10px;
  position: relative;
  z-index: -1;
  border-radius: 0 0 4px 4px;
  &__title {
    color: var(--primary-color-pink, #FE4DCD);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
  }
  &__item {
    display: flex;
    align-items: center;
    margin: 0 0 8px;
    color: var(--basic-color-5, #111);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    svg {
      width: 16px;
      margin-right: 4px;
    }
  }
}
.settings {
  padding: 64px 0 96px;
}
.settings-block {
  max-width: 570px;
  margin: auto auto 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  &__title {
    color: var(--basic-color-5, #111);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    margin-bottom: 0;
  }
  &__btn {
    margin-top: auto;
  }
  &__btn.settings-block__btn--small {
    padding: 14px 32px;
    color: var(--basic-color-5, #111);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    min-width: 165px;
  }
  &__btn.settings-block__btn--big {
    width: 50%;
  }

  &__label {
    color: var(--basic-color-4, #7E7E7E);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    display: flex;
    flex-direction: column;
    width: 50%;

    .select-header {
      margin-top: 4px;
    }
    &--large {
      width: 100%;
    }
    input {
      border-radius: 100px;
      border: 1px solid var(--basic-color-2, #DFDFDF);
      background: var(--basic-color-0, #FFF);
      padding: 8px 16px;
      color: var(--basic-color-4, #7E7E7E);
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-top: 4px;
      outline: none;
    }
  }
}
.label {
  color: var(--basic-color-4, #7E7E7E);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  margin-top: -8px;
}
.settings-thumbnail {
  &__row {
    display: flex;
    margin-top: 4px;
    gap: 16px;
  }
  &__picture {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
  }
  &__img {
    width: 100%;
  }
  &__btn {
    display: flex;
    padding: 14px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 100px;
    border: 1px solid var(--basic-color-4, #7E7E7E);
    background: var(--basic-color-0, #FFF);
    color: var(--basic-color-5, #111);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    outline: none;
    transition: .3s;
    cursor: pointer;
    &:hover {
      background: var(--bg-color-pink, #FFF5FC);
    }
  }
  &__remove {
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    color: var(--basic-color-5, #111);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
.settings-block-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  &__row {
    display: flex;
    gap: 30px;
    align-items: flex-start;
  }
}
.site-header-menu__mob {
  display: none;
}

input:focus {
  color: var(--basic-color-5, #111);
}
@media (pointer: fine) {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  .select-header,
  textarea {
    transition: .3s;
    &:hover {
      border: 1px solid var(--basic-color-5, #111);
    }
  }
}