footer {
  background: var(--basic-color-5, #111);
  padding-top: 38px;
  padding-bottom: 80px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.site-footer {
  &__logo {
    max-width: 170px;
    width: 100%;
    display: block;
    margin-right: auto;
  }
  &__row {
    display: flex;
    align-items: flex-start;
    gap: 70px;
  }
  &__menu {
    max-width: 222px;
  }
}
.site-footer-menu {
  &__name {
    color: var(--basic-color-3, #999);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-top: 5px;
    margin-bottom: 8px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__link {
    color: var(--basic-color-2, #DFDFDF);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &__question {
    color: var(--basic-color-2, #DFDFDF);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &__btn {
    border-radius: 100px;
    border: 1px solid var(--basic-color-0, #FFF);
    background: var(--basic-color-5, #111);
    padding: 14px 27px;
    color: #fff;
    display: block;
    text-decoration: none;
    color: var(--basic-color-0, #FFF);
    /* font-family: Montserrat; */
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    transition: .3s;

    &:hover {
      background: #222;
    }
  }
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
  opacity: 0;
  pointer-events: none;
  z-index: 24;

  &--study {

    .modal {
      padding: 24px 32px;
      display: flex;
      width: 514px;
      &__close {
        right: 16px;
      }
    }
  }

  &.active {
    pointer-events: all;
    opacity: 1;
  }

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: -1;
    /* opacity: .5; */
    background: rgba(17, 17, 17, 0.90);
    backdrop-filter: blur(8px);
  }
}
.modal-dictionary {
  &__name {
    display: flex;
    color: var(--basic-color-5, #111);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    align-items: center;
    gap: 8px;
    margin-bottom: 28px;
  }
  &__translation {
    color: var(--basic-color-5, #111);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #DFDFDF;
    width: 100%;
  }
  &__form {
    display: flex;
    align-items: flex-end;
    gap: 30px;
    .settings-block__label {
      width: 255px;
    }
    .modal-dictionary__btn {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 8px 16px;

      svg {
        width: 24px;
      }
    }
  }
}
.modal {
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: var(--basic-color-0, #FFF);
  padding: 16px 32px 32px 32px;
  gap: 8px;
  width: 335px;
  display: none;

  &.opened {
    display: flex;
  }

  &--password {
    width: 500px;
    max-width: 90%;
  }

  &__close {
    position: absolute;
    right: 32px;
    top: 16px;
    width: 32px;
    height: 32px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: opacity .3s;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 32 32'%3E%3Cpath fill='%23111' d='M25.53 24.47a.75.75 0 1 1-1.06 1.06L16 17.061 7.53 25.53a.75.75 0 0 1-1.06-1.06L14.94 16 6.47 7.53a.75.75 0 0 1 1.06-1.06L16 14.939l8.47-8.469a.75.75 0 0 1 1.06 1.06L17.061 16l8.47 8.47Z'/%3E%3C/svg%3E") no-repeat center / 100% 100%;

    &:hover {
      opacity: .5;
    }
  }
  &__name {
    color: var(--basic-color-5, #111);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 24px;
  }
  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 24px;
  }
  &__network {
    margin-bottom: 22px;
  }
  &__network-title {
    color: #111;
    font-weight: 700;
    margin-bottom: 8px;
  }
  &__network-btns {
    display: flex;
    gap: 8px;
  }
  &__label {
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: var(--basic-color-4, #7E7E7E);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    margin-bottom: 16px;
  }
  &__input {
    color: var(--basic-color-4, #7E7E7E);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 8px 16px;
    border-radius: 100px;
    border: 1px solid var(--basic-color-2, #DFDFDF);
    background: var(--basic-color-0, #FFF);
    width: 100%;
    outline: none;
  }
  &__btn {
    margin-top: 16px;
  }
  &__link {
    margin: auto;
    color: var(--basic-color-5, #111);
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}