@charset "UTF-8";
html, * {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.container {
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
  width: 100%;
}

.btn {
  cursor: pointer;
}

header ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: var(--basic-color-0, #FFF);
  box-shadow: 1px 1px 0px 0px #CACACD;
  display: flex;
  align-items: center;
  z-index: 5;
}
.site-header__logo {
  display: block;
  width: 145px;
}
.site-header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.site-header-menu {
  display: flex;
  align-items: center;
}
.site-header-menu__list {
  display: flex;
  align-items: center;
}

.site-header-menu-list__sub-menu {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  transition: 0.5s;
}
.site-header-menu-list__item {
  padding-left: 12px;
  padding-right: 12px;
}
.site-header-menu-list__item--parent {
  padding-right: 24px;
  position: relative;
}
.site-header-menu-list__item--parent::after {
  content: "";
  position: absolute;
  right: 0;
  top: 1px;
  width: 16px;
  height: 16px;
  transition: 0.5s;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='%23000' fill-opacity='.85' d='M8.005 13c.23 0 .46-.094.617-.272l7.13-7.446A.845.845 0 0 0 16 4.68c0-.489-.36-.864-.838-.864a.866.866 0 0 0-.599.244l-7.056 7.352h.986L1.437 4.061a.833.833 0 0 0-.599-.244C.36 3.817 0 4.192 0 4.68c0 .234.092.441.249.61l7.13 7.437a.857.857 0 0 0 .626.272Z'/%3E%3C/svg%3E") no-repeat center/100% 100%;
}
.site-header-menu-list__item--parent:hover .site-header-menu-list__sub-menu {
  opacity: 1;
  pointer-events: all;
}
.site-header-menu-list__item--parent:hover::after {
  transform: scaleY(-1);
}
.site-header-menu-list__item--search {
  padding-left: 24px;
  position: relative;
  z-index: 3;
}
.site-header-menu-list__inner {
  padding: 5px 5px;
  background: #fff;
  text-align: center;
}
.site-header-menu-list__link {
  color: var(--basic-color-5, #111);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
}
.site-header-menu-list__link--has-dropdown {
  font-weight: 700;
}
.site-header-menu-list__link:hover {
  text-decoration: underline;
}

.site-search-item__link {
  display: flex;
  align-items: center;
  gap: 20px;
  color: #000;
  text-decoration: none;
}
.site-search-item__link:hover {
  color: #FE4DCD;
  text-decoration: none;
}
.site-search-item-left {
  display: flex;
  flex-direction: column;
  text-decoration: none;
}
.site-search-item__name {
  font-weight: 600;
  font-size: 16px;
}
.site-search-item__link img {
  max-width: 32px;
  max-height: 32px;
  object-fit: cover;
  border-radius: 50%;
}

.site-search__list {
  position: absolute;
  right: 12px;
  top: calc(100% + 16px);
  width: 250px;
  max-width: 400px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #fff;
  box-shadow: 1px 1px 2px 0 #ccc;
  border-radius: 0 0 4px 4px;
  gap: 10px;
  transition: 0.3s;
  overflow: auto;
}
.site-search__input {
  width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 100px;
  border: 1px solid var(--basic-color-4, #7E7E7E);
  background: var(--basic-color-0, #FFF);
  min-height: 48px;
  transition: 0.3s;
  outline: none;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 32 32'%3E%3Cpath fill='%23111' d='m28.53 27.47-6.422-6.422a10.769 10.769 0 1 0-1.06 1.06l6.422 6.422a.75.75 0 0 0 1.06-1.06ZM4.75 14A9.25 9.25 0 1 1 14 23.25 9.261 9.261 0 0 1 4.75 14Z'/%3E%3C/svg%3E") no-repeat 16px 7px/32px 32px;
  padding: 8px 16px 8px 58px;
  min-width: 250px;
}
.site-search__input::placeholder {
  color: var(--basic-color-3, #999);
}
.site-search__input:not(:focus):placeholder-shown {
  padding: 0;
  min-width: 64px;
}
.site-search__input:not(:focus):placeholder-shown + .site-search__list {
  opacity: 1;
  pointer-events: all;
}
.site-search__input:not(:focus):placeholder-shown::placeholder {
  color: transparent;
}

.site-account-item__link {
  display: flex;
  align-items: center;
  gap: 16px;
  text-decoration: none;
  padding: 4px 8px;
  transition: 0.3s;
  color: var(--basic-color-5, #111);
}
.site-account-item__link path {
  transition: 0.3s;
}
.site-account-item__link:hover {
  border-radius: 4px;
  background: var(--bg-color-pink, #FFF5FC);
  color: var(--primary-color-pink, #FE4DCD);
}
.site-account-item__link:hover path {
  fill: var(--primary-color-pink, #FE4DCD);
}
.site-account-item__img {
  width: 24px;
}
.site-account-item__name {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.site-account-item__left {
  color: var(--basic-color-3, #999);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.site-account {
  position: relative;
  padding-right: 0;
}
.site-account__list {
  position: absolute;
  border-radius: 0px 0px 4px 4px;
  background: var(--basic-color-0, #FFF);
  padding: 8px;
  width: 268px;
  right: 0;
  top: calc(100% + 16px);
  box-shadow: 1px 1px 2px 0 #ccc;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
}
.site-account__list.active {
  opacity: 1;
  pointer-events: all;
}
.site-account__btn {
  display: flex;
  align-items: center;
  padding: 8px 32px;
  border-radius: 100px;
  border: 1px solid var(--basic-color-4, #7E7E7E);
  background: var(--basic-color-0, #FFF);
  gap: 10px;
  outline: none;
  transition: 0.3s;
  cursor: pointer;
}
.site-account__btn:hover {
  background: var(--bg-color-pink, #FFF5FC);
}
.site-account__btn.active {
  background: var(--bg-color-pink, #FFF5FC);
}
.site-account__btn.active svg {
  transform: scaleY(-1);
}
.site-account__btn svg {
  width: 16px;
  transition: 0.3s;
}
.site-account__img {
  width: 32px;
  aspect-ratio: 1/1;
  border-radius: 50%;
}
.site-account__text {
  width: 32px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color-pink, #FE4DCD);
  color: white;
}
.site-account__name {
  color: var(--basic-color-5, #111);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.btn {
  border-radius: 100px;
  padding: 14px 32px;
  text-decoration: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  transition: 0.3s;
  display: inline-block;
}
.btn--transparent {
  border: 1px solid var(--basic-color-4, #7E7E7E);
  background: var(--basic-color-0, #FFF);
  color: var(--basic-color-5, #111);
}
.btn--transparent:hover {
  background: var(--bg-color-pink, #FFF5FC);
}
.btn--transparent-new {
  border: 1px solid var(--basic-color-4, #7E7E7E);
  background: var(--basic-color-0, #FFF);
  color: var(--basic-color-5, #111);
}
.btn--transparent-new:hover {
  background: #FF90E0;
  color: #111;
}
.btn--white {
  border: 1px solid var(--basic-color-4, #FFF);
  background: var(--basic-color-0, #FFF);
  color: var(--basic-color-5, #111);
}
.btn--white:hover {
  border-color: var(--primary-color-pink, #FE4DCD);
  background: var(--primary-color-pink, #FE4DCD);
}
.btn--black {
  border: 1px solid var(--basic-color-5, #111);
  background: var(--basic-color-5, #111);
  color: var(--basic-color-0, #FFF);
}
.btn--black:hover {
  border-color: var(--primary-color-pink, #FE4DCD);
  background: var(--primary-color-pink, #FE4DCD);
}
.btn--network {
  background: transparent;
  padding: 8px 14px;
  border: 1px solid #7E7E7E;
  display: flex;
  position: relative;
}
.btn--network:hover {
  background: var(--bg-color-pink, #FFF5FC);
}
.btn--network-disabled {
  background: transparent;
  padding: 8px 14px;
  border: 1px solid #7E7E7E;
  display: flex;
  position: relative;
}
.btn--network-disabled svg {
  width: 32px;
}
.btn--network > button {
  background-color: red;
}
.btn--network svg {
  width: 32px;
}

.burger-btn {
  display: none;
}

h2 {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
}

h1, .h1 {
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
}

p {
  color: var(--basic-color-5, #111);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

main {
  padding-top: 80px;
}
.toast-payment {
  display: none;
  width: 100%;
}
.toast__success-payment {
  color: #FFFFFF;
  background: #4D9F0C;
}
.toast__warning-payment {
  background: #FEC24D;
}
.toast__error-payment {
  color: #FFFFFF;
  background: #C51D1D;
}
.toast__error-payment svg path {
  fill: #FFFFFF;
}
.toast-payment.toast-payment__hidden {
  display: block;
}
.toast-payment.in-player {
  position: fixed;
  top: 0;
  z-index: 9;
}
.toast__payment__container{
  display: flex;
  height: 40px;
  max-width: 1200px;
  align-items: center;
  margin: auto;
  padding-inline: 15px;
}
.toast__payment__text {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}
.toast__payment__close {
  display: flex;
  margin-left: auto;
  cursor: pointer;
}
.toast__success-payment .toast__payment__close path{
  fill: #FFFFFF;
}

.common-header {
  background: var(--bg-color-gray, #FAFAFA);
}
.common-header__list {
  display: flex;
  align-items: center;
}
.common-header__link {
  padding: 16px 8px;
  color: var(--basic-color-5, #111);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  display: block;
  line-height: 20px;
  width: 177px;
  text-decoration: none;
  transition: 0.3s;
  display: flex;
  align-items: center;
}
.common-header__link span:nth-child(1) {
  width: 100%;
}
.common-header__link:hover {
  background: #FFF5FC;
}
.common-header__link.active {
  background: #FFF5FC;
  box-shadow: 0px 1px 0px 0px #FE4DCD;
}
.common-header__icon {
  width: 24px;
  margin-right: 16px;
}

main {
  min-height: 75vh;
}

footer {
  background: var(--basic-color-5, #111);
  padding-top: 38px;
  padding-bottom: 80px;
}
footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.site-footer__logo {
  max-width: 170px;
  width: 100%;
  display: block;
  margin-right: auto;
}
.site-footer__row {
  display: flex;
  align-items: flex-start;
  gap: 70px;
}
.site-footer__menu {
  max-width: 222px;
}

.site-footer-menu__name {
  color: var(--basic-color-3, #999);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 5px;
  margin-bottom: 8px;
}
.site-footer-menu__list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.site-footer-menu__link {
  color: var(--basic-color-2, #DFDFDF);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
}
.site-footer-menu__link:hover {
  text-decoration: underline;
}
.site-footer-menu__question {
  color: var(--basic-color-2, #DFDFDF);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
}
.site-footer-menu__question:hover {
  text-decoration: underline;
}
.site-footer-menu__btn {
  border-radius: 100px;
  border: 1px solid var(--basic-color-0, #FFF);
  background: var(--basic-color-5, #111);
  padding: 14px 27px;
  color: #fff;
  display: block;
  text-decoration: none;
  color: var(--basic-color-0, #FFF);
  /* font-family: Montserrat; */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  transition: 0.3s;
}
.site-footer-menu__btn:hover {
  background: #222;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  opacity: 0;
  pointer-events: none;
  z-index: 24;
}
.overlay--study .modal {
  padding: 24px 32px;
  display: flex;
  width: 514px;
}
.overlay--study .modal__close {
  right: 16px;
}
.overlay.active {
  pointer-events: all;
  opacity: 1;
}
.overlay__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: -1;
  /* opacity: .5; */
  background: rgba(17, 17, 17, 0.9);
  backdrop-filter: blur(8px);
}

.modal-dictionary__name {
  display: flex;
  color: var(--basic-color-5, #111);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-items: center;
  gap: 8px;
  margin-bottom: 28px;
}
.modal-dictionary__translation {
  color: var(--basic-color-5, #111);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #DFDFDF;
  width: 100%;
}
.modal-dictionary__form {
  display: flex;
  align-items: flex-end;
  gap: 30px;
}
.modal-dictionary__form .settings-block__label {
  width: 255px;
}
.modal-dictionary__form .modal-dictionary__btn {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 16px;
}
.modal-dictionary__form .modal-dictionary__btn svg {
  width: 24px;
}

.modal {
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: var(--basic-color-0, #FFF);
  padding: 16px 32px 32px 32px;
  gap: 8px;
  width: 335px;
  display: none;
}
.modal.opened {
  display: flex;
}
.modal--password {
  width: 500px;
  max-width: 90%;
}
.modal__close {
  position: absolute;
  right: 32px;
  top: 16px;
  width: 32px;
  height: 32px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: opacity 0.3s;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 32 32'%3E%3Cpath fill='%23111' d='M25.53 24.47a.75.75 0 1 1-1.06 1.06L16 17.061 7.53 25.53a.75.75 0 0 1-1.06-1.06L14.94 16 6.47 7.53a.75.75 0 0 1 1.06-1.06L16 14.939l8.47-8.469a.75.75 0 0 1 1.06 1.06L17.061 16l8.47 8.47Z'/%3E%3C/svg%3E") no-repeat center/100% 100%;
}
.modal__close:hover {
  opacity: 0.5;
}
.modal__name {
  color: var(--basic-color-5, #111);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 24px;
}
.modal__form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
}
.modal__network {
  margin-bottom: 22px;
}
.modal__network-title {
  color: #111;
  font-weight: 700;
  margin-bottom: 8px;
}
.modal__network-btns {
  display: flex;
  gap: 8px;
}
.modal__label {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: var(--basic-color-4, #7E7E7E);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 16px;
}
.modal__input {
  color: var(--basic-color-4, #7E7E7E);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 8px 16px;
  border-radius: 100px;
  border: 1px solid var(--basic-color-2, #DFDFDF);
  background: var(--basic-color-0, #FFF);
  width: 100%;
  outline: none;
}
.red_text {
  font-size: 10px;
  margin: 0;
  color: red;
}
.green_text {
  font-size: 10px;
  margin: 0;
  color: green;
}
.modal__btn {
  margin-top: 16px;
}
.modal__link {
  margin: auto;
  color: var(--basic-color-5, #111);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
}
.modal__link:hover {
  text-decoration: underline;
}

.hero {
  background: url("../img/hero-bg.png") no-repeat center/cover;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 200px 0;
  text-align: center;
}
.hero__title {
  color: var(--basic-color-0, #FFF);
  text-align: center;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px; /* 109.375% */
  margin: 0;
}
.hero__text {
  color: var(--basic-color-0, #FFF);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin: 0;
}
.hero__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  max-width: 1366px;
  padding: 0 15px;
}

.container--assimetric {
  max-width: 1800px;
}

.main-info {
  padding: 96px 0;
}
.main-info__row {
  width: 1466px;
  display: flex;
  align-items: center;
  gap: 60px;
}
.main-info__img {
  filter: drop-shadow(16px 16px 32px rgba(0, 0, 0, 0.25));
  border-radius: 15px;
  width: 775px;
}
.main-info__content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
}
.main-info__title {
  color: var(--basic-color-5, #111);
  margin: 0;
}

.more-info {
  background: var(--basic-color-1, #F1F1F4);
  padding: 96px 0;
}
.more-info__container {
  max-width: 1105px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  text-align: center;
}
.more-info__title {
  margin: 0;
  max-width: 770px;
  text-align: center;
}
.more-info__img {
  max-width: 100%;
  border-radius: 10px;
  filter: drop-shadow(16px 16px 32px rgba(0, 0, 0, 0.25));
}
.more-info__text {
  margin-top: 6px;
  max-width: 770px;
}

.about {
  padding: 96px 0;
}
.about__row {
  display: flex;
  align-items: center;
  gap: 30px;
}
.about__img {
  width: 470px;
}
.about__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.about__title {
  margin: 0;
}

.videos {
  padding-top: 32px;
}
.videos__list {
  margin: 37px 0 96px;
}

.filter {
  padding: 24px;
  border-radius: 8px;
  background: var(--bg-color-gray, #FAFAFA);
  position: relative;
}
.filter__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  z-index: 1;
}
.filter__title {
  color: var(--basic-color-5, #111);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
}
.filter__total {
  color: var(--basic-color-4, #7E7E7E);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
}
.filter__total span {
  color: var(--basic-color-5, #111);
}
.filter__body {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(5, 1fr);
}
@media (max-width: 1200px) {
  .filter__body {
    grid-template-columns: repeat(2, 1fr);
  }
}
.filter__label {
  display: flex;
  flex-direction: column;
  color: var(--basic-color-4, #7E7E7E);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  gap: 4px;
  position: relative;
}

.filter__label.active .select-header {
  z-index: 10;
}

.filter__label select {
  color: var(--basic-color-4, #7E7E7E);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 8px 16px;
  border-radius: 100px;
  outline: none;
}

/* Сховати початковий <select> */
.select {
  display: none;
}

/* Створити стилізований кастомний селект */
.custom-select {
  position: relative;
  color: var(--basic-color-4, #7E7E7E);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0;
  border-radius: 100px;
  outline: none;
}

.select-header {
  border-radius: 100px;
  border: 1px solid var(--basic-color-2, #DFDFDF);
  background: var(--basic-color-0, #FFF);
  display: flex;
  padding: 8px 32px 8px 16px;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
  user-select: none;
}
.select-header::after {
  content: "";
  position: absolute;
  right: 16px;
  top: 13px;
  width: 16px;
  height: 16px;
  transition: 0.5s;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='%23000' fill-opacity='.85' d='M8.005 13c.23 0 .46-.094.617-.272l7.13-7.446A.845.845 0 0 0 16 4.68c0-.489-.36-.864-.838-.864a.866.866 0 0 0-.599.244l-7.056 7.352h.986L1.437 4.061a.833.833 0 0 0-.599-.244C.36 3.817 0 4.192 0 4.68c0 .234.092.441.249.61l7.13 7.437a.857.857 0 0 0 .626.272Z'/%3E%3C/svg%3E") no-repeat center/100% 100%;
}
.select-header.opened::after {
  transform: scaleY(-1);
}

.options-list {
  list-style: none;
  padding: 20px 0 0;
  margin: -20px 0 0;
  border: 1px solid #ccc;
  max-height: 0;
  opacity: 0;
  overflow-y: auto;
  transition: 0.3s;
  background-color: #fff;
  z-index: 8;
  position: absolute;
  width: 100%;
}
.options-list.opened {
  max-height: 500px;
  opacity: 1;
}

.options-list li {
  padding: 8px;
  cursor: pointer;
}

.options-list li:hover {
  background-color: #f0f0f0;
}

.videos-list {
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}


.videos-list__item {
  position: relative;
  border-radius: 0px;
  overflow: hidden;
  height: 410px;
}

.videos-list__item:hover .videos-list-item__link {
  opacity: 1;
  pointer-events: all;
}
.videos-list__item:hover .videos-list__item-title {
  opacity: 0;
}
.videos-list-item {
  height: 93%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.videos-list-item__link {
  position: absolute;
  top: 0;
  color: #fff;
  text-decoration: none;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  background: var(--basic-color-5, #111);
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
}
.videos-list__item-title {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 7%;
  width: 100%;
  height: max-content;
  bottom: 0;
  font-size: 13px;
  line-height: 22px;
  max-height: 22px;
  color: rgb(145, 159, 164);
}
.videos-list-item__name {
  color: var(--basic-color-0, #FFF);
  /* font-family: Poppins; */
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.videos-list-item__points {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  list-style: none;
}
.videos-list-item__point {
  border-radius: 100px;
  background: #333;
  padding: 0 8px;
  color: var(--basic-color-1, #F1F1F4);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.videos-list-item__description {
  overflow: hidden;
  color: var(--basic-color-3, #999);
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0;
  margin-bottom: 0;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.videos-list-item__add {
  overflow: hidden;
  color: var(--basic-color-2, #DFDFDF);
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  display: flex;
  align-items: center;
  min-height: 30px;
}
.videos-list-item__fav {
  margin-left: 8px;
  padding: 0;
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
}
.videos-list-item__fav:hover path, .videos-list-item__fav.active path {
  fill: red;
}

.video {
  padding-top: 32px;
  padding-bottom: 96px;
}
.video__row {
  display: flex;
  gap: 30px;
  max-width: 990px;
  margin: auto;
}
.video__sidebar {
  width: 275px;
  min-width: 275px;
  display: flex;
  flex-direction: column;
}
.video__sidebar img {
  height: 410px;
  object-fit: cover;
  border-radius: 4px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.video__btns {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0;
}
.video__btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.video__btn:hover path {
  fill: red;
}
.video__btn svg {
  width: 24px;
  margin-left: 10px;
}
.video__btn path {
  fill: red;
  transition: 0.3s;
}
.video__name {
  margin: 0 0 6px;
  color: var(--basic-color-5, #111);
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}
.video__description p {
  overflow: hidden;
  color: var(--basic-color-4, #7E7E7E);
  text-overflow: ellipsis;
  whitespace: nowrap;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.video__main {
  margin: 24px 0;
}

.video-main__row {
  overflow: hidden;
  color: var(--basic-color-5, #111);
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.video-main__link {
  color: inherit;
}
.video-main__link:hover {
  text-decoration: none;
}

.video-table__tabs {
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  background: var(--basic-color-1, #F1F1F4);
  width: fit-content;
  border: 1px solid var(--basic-color-1, #F1F1F4);
  margin-bottom: 18px;
}
.video-table__tab {
  outline: none;
  border-radius: 4px;
  background: transparent;
  /* background: var(--basic-color-0, #FFF); */
  padding: 14px 16px;
  border: none;
  color: var(--basic-color-5, #111);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  transition: color 0.3s, background-position 0.3s;
}
.video-table__tab:not(.active):hover {
  color: var(--primary-color-pink, #FE4DCD);
}
.video-table__tab.active {
  background: var(--basic-color-0, #FFF);
  font-weight: 600;
}

.video-future {
  border-radius: 4px;
  background: var(--bg-color-gray, #FAFAFA);
  padding: 8px 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  align-items: flex-start;
}
.video-future__title {
  margin: 0;
  color: var(--basic-color-5, #111);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.video-future__text {
  margin: 0;
  color: var(--basic-color-4, #7E7E7E);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.video-table__seasons-title {
  font-weight: bolder;
}

.season-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.season {
  display: none;
}
.season.activetab {
  display: block;
}

.season-list-item {
  background: var(--basic-color-0, #FFF);
  padding: 8px 0;
  display: flex;
  border-bottom: 1px solid #F1F1F4;
}
.season-list-item__number {
  color: var(--basic-color-5, #111);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-right: 16px;
}
.season-list-item__name-block {
  display: flex;
  align-items: center;
}
.season-list-item__name {
  display: flex;
  max-width: 320px;
  align-items: center;
  background: transparent;
  border: none;
  color: var(--primary-color-pink, #FE4DCD);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
  padding: 0;
  cursor: pointer;
  margin-right: 7px;
}
.season-list-item__name:hover {
  text-decoration: none;
}
.season-list-item__description {
  margin-top: 10px;
  transition: 0.3s;
  max-height: 0;
  overflow: hidden;
}
.season-list-item__description.opened {
  max-height: 300px;
}
.season-list-item__description p {
  font-size: 13px;
  line-height: 1.2;
}
.season-list-item__more {
  background: transparent;
  border: none;
  padding: 0;
  color: var(--basic-color-4, #7E7E7E);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  margin-top: 4px;
  cursor: pointer;
}
.season-list-item__more:hover {
  text-decoration: underline;
}
.season-list-item__info {
  text-align: right;
  margin-left: auto;
  max-width: 300px;
  min-width: 180px;
}
.season-list-item__flags {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
  flex-wrap: nowrap;
  float: right;
}
.season-list-item__flags .season-list-item__download {
  cursor: pointer;
}
.season-list-item__flags img {
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.season-list-item__update {
  color: var(--basic-color-4, #7E7E7E);
  text-align: right;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.terms, .faq {
  padding: 32px 0 48px;
}
.terms__content, .faq__content {
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
  color: var(--basic-color-5, #111);
}
.terms__content h1, .faq__content h1 {
  margin: 0 0 16px;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}
.terms__content h2, .faq__content h2 {
  margin-top: 16px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
}
.terms__content p, .terms__content li, .faq__content p, .faq__content li {
  line-height: 24px; /* 150% */
}
.terms__content a, .faq__content a {
  color: inherit;
  font-weight: 600;
}
.terms__content a:hover, .faq__content a:hover {
  text-decoration: none;
}

.faq {
  padding-top: 64px;
  padding-bottom: 110px;
}
.faq__items {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.faq-item {
  border-radius: 8px;
  background: var(--bg-color-gray, #FAFAFA);
  overflow: hidden;
}
.faq-item.opened .faq-item__body {
  padding-top: 8px;
  padding-bottom: 8px;
  max-height: 600px;
}
.faq-item.opened .faq-item__header::after {
  transform: scaleY(-1);
}
.faq-item__header {
  color: var(--basic-color-5, #111);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 8px 40px 8px 24px;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
}
.faq-item__header:hover {
  background: #FFF5FC;
}
.faq-item__header::after {
  content: "";
  position: absolute;
  right: 24px;
  top: 12px;
  width: 16px;
  height: 16px;
  transition: 0.5s;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='%23000' fill-opacity='.85' d='M8.005 13c.23 0 .46-.094.617-.272l7.13-7.446A.845.845 0 0 0 16 4.68c0-.489-.36-.864-.838-.864a.866.866 0 0 0-.599.244l-7.056 7.352h.986L1.437 4.061a.833.833 0 0 0-.599-.244C.36 3.817 0 4.192 0 4.68c0 .234.092.441.249.61l7.13 7.437a.857.857 0 0 0 .626.272Z'/%3E%3C/svg%3E") no-repeat center/100% 100%;
}
.faq-item__body {
  padding: 0 24px;
  color: var(--basic-color-4, #7E7E7E);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  transition: 0.3s;
  max-height: 0;
  overflow: hidden;
}

.dictionary {
  padding: 34px 0 96px;
}

.dictionary-main {
  display: flex;
  align-items: flex-start;
  gap: 30px;
}

.sidebar {
  width: 270px;
}
.sidebar__name {
  color: var(--basic-color-5, #111);
  /* H3 - bold */
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 112.5% */
  margin-top: 0;
  margin-bottom: 32px;
}
.sidebar__btn {
  width: 100%;
  margin-bottom: 24px;
  background-color: #FE4DCD;
  color: #fff;
}
.sidebar__btn:hover {
  color: var(--basic-color-5, #111);
}

.sidebar-check {
  margin-bottom: 24px;
}
.sidebar-check input {
  display: none;
}
.sidebar-check input:checked + .sidebar-checkbox__label::after {
  content: "";
  width: 23px;
  height: 23px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgPHBhdGggc3Ryb2tlPSIjRkU0RENEIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgZD0ibTE2IDktNS41IDVMOCAxMS43MjgiLz4KPC9zdmc+Cg==") no-repeat center/100% 100%;
}

.sidebar-checkbox__label {
  color: var(--basic-color-4, #7E7E7E);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  padding-left: 32px;
  position: relative;
  cursor: pointer;
}
.sidebar-checkbox__label::before {
  content: "";
  width: 23px;
  height: 23px;
  border-radius: 3px;
  border: 1px solid var(--basic-color-4, #7E7E7E);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.sidebar-block {
  border-radius: 8px;
  background: var(--bg-color-gray, #FAFAFA);
  padding: 24px 16px;
  margin-bottom: 24px;
}
.sidebar-block__title {
  margin: 0 0 8px;
  color: var(--basic-color-5, #111);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.sidebar-block__items {
  padding: 0;
  margin: 0;
  list-style: none;
}
.sidebar-block__item {
  display: flex;
  align-items: center;
}
.sidebar-block__item input {
  display: none;
}
.sidebar-block__item input:checked + .sidebar-block__label {
  color: var(--primary-color-pink, #FE4DCD);
}
.sidebar-block__item:not(:last-child) {
  border-bottom: 1px solid #E8E8E8;
}
.sidebar-block__label {
  padding: 8px;
  display: block;
  cursor: pointer;
}
.sidebar-block__count {
  color: var(--basic-color-4, #7E7E7E);
  text-align: right;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  margin-left: auto;
}
.dictionary-select__language{
  margin: 30px 0;


}
.dictionary-menu-list__link {
  color: var(--basic-color-5, #111);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  text-transform: capitalize;
  line-height: 24px;
}

.dictionary-search {
  margin-right: 32px;
}
.dictionary-search__input {
  border-radius: 100px;
  border: 1px solid var(--basic-color-4, #7E7E7E);
  background: var(--basic-color-0, #FFF);
  padding: 12px 16px 12px 58px;
  min-width: 300px;
  outline: none;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 32 32'%3E%3Cpath fill='%23111' d='m28.53 27.47-6.422-6.422a10.769 10.769 0 1 0-1.06 1.06l6.422 6.422a.75.75 0 0 0 1.06-1.06ZM4.75 14A9.25 9.25 0 1 1 14 23.25 9.261 9.261 0 0 1 4.75 14Z'/%3E%3C/svg%3E") no-repeat 16px 7px/31px 31px;
}
.dictionary-search__input::placeholder {
  color: var(--basic-color-4, #7E7E7E);
  /* Body - regular */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.dictionary-section {
  width: 870px;
}
.dictionary-section__head {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 48px;
  margin-bottom: 18px;
}
.dictionary-section__item--type {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: var(--basic-color-1, #F1F1F4);
  padding: 2px;
  gap: 8px;
}

.dictionary-type input {
  display: none;
}
.dictionary-type input:checked + .dictionary-type__label {
  border-radius: 4px;
  background: var(--basic-color-0, #FFF);
}
.dictionary-type__label {
  display: flex;
  align-items: center;
  padding: 8px 22px;
  transition: 0.3s;
  cursor: pointer;
}
.dictionary-type__label svg {
  width: 32px;
}

button.btn--icon {
  border-radius: 8px;
  background: var(--bg-color-gray, #FAFAFA);
  border: none;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  color: var(--basic-color-5, #1A1A1A);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 8px 16px;
  width: 100%;
}
button.btn--icon svg {
  width: 24px;
  margin-right: 8px;
}

.dictionary-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}
.dictionary-list--listing {
  grid-template-columns: 1fr;
  gap: 8px;
}
.dictionary-list--listing .dictionary-item__languages {
  display: none;
}
.dictionary-list--listing .dictionary-item__translation {
  color: var(--basic-color-5, #111);
  /* Body - regular */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.dictionary-list--listing .dictionary-item__translation,
.dictionary-list--listing .dictionary-item__header {
  margin-bottom: 0;
}
.dictionary-list--listing .dictionary-item {
  padding: 16px 32px;
}
.dictionary-list--listing .dictionary-item__header {
  gap: 8px;
}

.dictionary-item {
  border-radius: 8px;
  border: 1px solid var(--basic-color-2, #DFDFDF);
  background: var(--basic-color-0, #FFF);
  padding: 16px 32px 24px;
  transition: 0.3s;
}
.dictionary-item.hidden {
  padding-bottom: 16px;
}
.dictionary-item:hover {
  border: 1px solid var(--basic-color-3, #999);
}
.dictionary-item__header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.dictionary-item__header.hidden {
  margin-bottom: 0;
}
.dictionary-item__value {
  color: var(--primary-color-pink, #FE4DCD);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  transition: 0.3s;
}
.dictionary-item__value:hover {
  opacity: 0.8;
}
.dictionary-item__btns {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 6px;
}
.dictionary-item__translation {
  margin-bottom: 8px;
  color: var(--basic-color-5, #111);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dictionary-item__translation.hidden {
  display: none;
}
.dictionary-item__languages {
  color: var(--basic-color-4, #7E7E7E);
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}
.dictionary-item__languages.hidden {
  display: none;
}

.btn-icon {
  background: transparent;
  border: none;
  padding: 0;
  width: 24px;
  min-width: 24px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
}
.btn-icon path {
  transition: 0.3s;
}
.btn-icon:hover path {
  fill: #111;
  opacity: 1;
}

.notifications {
  padding: 64px 0 96px;
}

.notifications-block {
  max-width: 370px;
  margin: auto auto 32px;
}
.notifications-block__title {
  color: var(--basic-color-5, #111);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  margin: 0 0 16px;
}
.notifications-block__list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.notifications-block-item__name {
  color: var(--basic-color-5, #111);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.custom-checkbox {
  display: none;
}
.custom-checkbox__label {
  display: flex;
  padding: 7px 0;
  align-items: center;
  border-bottom: 1px solid var(--basic-color-3, #999);
  min-height: 70px;
  margin-right: 20px;
}
.custom-checkbox__wrapper {
  position: relative;
  width: 51px;
  min-width: 51px;
  height: 31px;
  background-color: var(--basic-color-2, #DFDFDF);
  border-radius: 999px;
  cursor: pointer;
  pointer-events: all;
  margin-left: auto;
  transition: 0.3s;
}
.custom-checkbox__selector {
  filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.06)) drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.15));
  background: #fff;
  width: 27px;
  height: 27px;
  display: block;
  border-radius: 50%;
  top: 2px;
  position: absolute;
  left: 2px;
  transition: 0.3s;
}
.custom-checkbox:checked + .custom-checkbox__wrapper {
  background: #C7349D;
}
.custom-checkbox:checked + .custom-checkbox__wrapper .custom-checkbox__selector {
  left: 22px;
}

.subscriptions {
  padding: 34px 0;
}

.subscriptions-container__block {
  display: flex;
  flex-direction: column;
}

.subscriptions__block-info {
  max-width: 260px;
  margin: 20px auto;
}

.subscriptions-block-info__title {
  margin-top: 0;
  font-size: 24px;
  font-weight: 700;
}

.subscriptions__choose-payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 38px 0 90px;
}

.subscriptions__choose-payment--paid {
  font-size: 24px;
  font-weight: 400;
  line-height: 33px;
  margin-bottom: 10px;
}

.subscriptions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
}

.subscriptions-list-item__link {
  border-radius: 4px;
  border: 1px solid var(--basic-color-2, #DFDFDF);
  background: var(--basic-color-0, #FFF);
  box-shadow: 0px -4px 0px 0px #4D4D4D;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: inherit;
  min-height: 230px;
  transition: 0.3s;
}
.subscriptions-list-item__link .btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.subscriptions-list-item__btn {
  background: #111;
  color: #fff;
}
.subscriptions-payment__btn .subscriptions-list-item__btn:last-child {
  margin-left: 24px;
}
.subscriptions-list-item__link .btn svg {
  margin-left: 10px;
}
.subscriptions-list-item__btn:hover {
  background: #fff;
  color: #111;
}
.subscriptions-list-item__link.active,
.subscriptions-list-item__link:hover {
  box-shadow: 0px -4px 0px 0px #FE4DCD;
}

.subscriptions-list-item__link--active {
  box-shadow: 0px -4px 0px 0px #999;
}
.subscriptions-list-item__period {
  color: var(--basic-color-5, #111);
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 16px;
  line-height: 33px;
}
.subscriptions-list-item__price {
  margin: 0 0 16px;
  color: var(--basic-color-5, #111);
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}
.subscriptions-list-item__discount {
  display: block;
  color: var(--basic-color-3, #999);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}
.subscriptions-list-item__list {
  padding: 0;
  list-style: none;
  margin: 0 0 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.subscriptions-list-item__point {
  color: var(--basic-color-5, #111);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding-left: 24px;
  position: relative;
}
.subscriptions-list-item__point::before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 16px;
  height: 16px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='%23111' d='m14.265 4.765-8 8a.375.375 0 0 1-.53 0l-3.5-3.5a.375.375 0 0 1 .53-.53L6 11.969l7.735-7.734a.375.375 0 0 1 .53.53Z'/%3E%3C/svg%3E") no-repeat center/100% 100%;
}
.subscriptions-list-item .btn {
  text-align: center;
}

.subscriptions-list-item-active {
  background: var(--bg-color-pink, #FFF5FC);
  padding: 21px 26px 10px;
  margin-top: -10px;
  position: relative;
  z-index: -1;
  border-radius: 0 0 4px 4px;
}
.subscriptions-list-item-active__title {
  color: var(--primary-color-pink, #FE4DCD);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
}
.subscriptions-list-item-active__item {
  display: flex;
  align-items: center;
  margin: 0 0 8px;
  color: var(--basic-color-5, #111);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}
.subscriptions-list-item-active__item svg {
  width: 16px;
  margin-right: 4px;
}
.subscriptions-purchase {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.subscriptions-purchase table {
  border-collapse: collapse;
  border-spacing: 0;
}
.subscriptions-purchase__title {
  margin: 20px auto;
  width: max-content;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
}

.subscriptions-purchase__list {
  opacity: 0.6;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.subscriptions-purchase__list-item{
  padding: 8px;
}
.subscriptions-purchase__list:first-child {
  opacity: 1;
  background-color: #F1F1F4;
}

.settings {
  padding: 64px 0 96px;
}

.settings-block {
  max-width: 610px;
  margin: auto auto 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.settings-block__title {
  color: var(--basic-color-5, #111);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  margin-bottom: 0;
}
.settings-block__btn {
  margin-top: auto;
}
.settings-block__btn.settings-block__btn--small {
  padding: 14px 32px;
  color: var(--basic-color-5, #111);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  min-width: 165px;
}
.settings-block__btn.settings-block__btn--big {
  width: 50%;
}
.settings-block__label {
  color: var(--basic-color-4, #7E7E7E);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 50%;
}
.settings-block__label.language_lvl{
  z-index: 3;
}
.settings-block__label .select-header {
  margin-top: 4px;
}
.settings-block__label--large {
  width: 100%;
}
.settings-block__label input {
  border-radius: 100px;
  border: 1px solid var(--basic-color-2, #DFDFDF);
  background: var(--basic-color-0, #FFF);
  padding: 8px 16px;
  color: var(--basic-color-4, #7E7E7E);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 4px;
  outline: none;
}
.read-only-field {
  background-color: #f5f5f5 !important; 
  border: 1px solid #ccc !important; 
  cursor: not-allowed; 
  color: #666 !important; 
}

.label {
  color: var(--basic-color-4, #7E7E7E);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  margin-top: -8px;
}

.settings-thumbnail__row {
  display: flex;
  margin-top: 4px;
  gap: 16px;
}
.settings-thumbnail__picture {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
.settings-thumbnail__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.settings-thumbnail__text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color-pink, #FE4DCD);
  color: white;
}

 .settings-thumbnail__btn {
  display: flex;
  padding: 14px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 100px;
  border: 1px solid var(--basic-color-4, #7E7E7E);
  background: var(--basic-color-0, #FFF);
  color: var(--basic-color-5, #111);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  outline: none;
  transition: 0.3s;
  cursor: pointer;
}
.settings-thumbnail__btn:hover {
  background: var(--bg-color-pink, #FFF5FC);
}
.settings-thumbnail__remove {
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  color: var(--basic-color-5, #111);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
}
.settings-thumbnail__remove:hover {
  text-decoration: underline;
}

.settings-block-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.settings-block-form__row {
  display: flex;
  gap: 30px;
  align-items: flex-start;
}

.site-header-menu__mob {
  display: none;
}

input:focus {
  color: var(--basic-color-5, #111);
}

@media (pointer: fine) {
  input[type=text],
  input[type=email],
  input[type=password],
  .select-header,
  textarea {
    transition: 0.3s;
  }
  input[type=text]:hover,
  input[type=email]:hover,
  input[type=password]:hover,
  .select-header:hover,
  textarea:hover {
    border: 1px solid var(--basic-color-5, #111);
  }
}

@media (max-width: 1200px) {
  .videos-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 720px) {
  .videos-list__item {
    height: 320px;
  }
  .videos-list {
    gap: 15px;
    grid-template-columns: repeat(2, 1fr);
  }

  .videos-list__item-title{
    max-height: 35px;
  }
}

