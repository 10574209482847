header ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: var(--basic-color-0, #FFF);
  box-shadow: 1px 1px 0px 0px #CACACD;
  display: flex;
  align-items: center;
  z-index: 5;

  &__logo {
    display: block;
    width: 145px;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.site-header-menu {
  display: flex;
  align-items: center;
  &__list {
    display: flex;
    align-items: center;
    //gap: 24px;
  }
}

.site-header-menu-list {
  &__sub-menu {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    pointer-events: none;
    opacity: 0;
    transition: .5s;
  }
  &__item {
    padding-left: 12px;
    padding-right: 12px;
    &--parent {
      padding-right: 24px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 1px;
        width: 16px;
        height: 16px;
        transition: .5s;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='%23000' fill-opacity='.85' d='M8.005 13c.23 0 .46-.094.617-.272l7.13-7.446A.845.845 0 0 0 16 4.68c0-.489-.36-.864-.838-.864a.866.866 0 0 0-.599.244l-7.056 7.352h.986L1.437 4.061a.833.833 0 0 0-.599-.244C.36 3.817 0 4.192 0 4.68c0 .234.092.441.249.61l7.13 7.437a.857.857 0 0 0 .626.272Z'/%3E%3C/svg%3E") no-repeat center / 100% 100%;
      }

      &:hover  {
        .site-header-menu-list {
          &__sub-menu {
            opacity: 1;
            pointer-events: all;
          }
        }
        &::after {
          transform: scaleY(-1);
        }
      }
    }
    &--search {
      padding-left: 24px;
      position: relative;
      z-index: 3;
    }
  }
  &__inner {
    padding: 5px 5px;
    background: #fff;
    text-align: center;
  }
  &__link {
    color: var(--basic-color-5, #111);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-decoration: none;

    &--has-dropdown {
      font-weight: 700;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}

.site-search-item {
  &__link {
    display: flex;
    align-items: center;
    gap: 20px;
    color: #000;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    img {
      width: 32px;
      aspect-ratio: 1/1;
      border-radius: 50%;
    }
  }
}




.site-search {
  &__list {
    position: absolute;
    right: 12px;
    top: calc(100% + 16px);
    width: max-content;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #fff;
    box-shadow: 1px 1px 2px 0 #ccc;
    border-radius: 0 0 4px 4px;
    gap: 10px;
    transition: .3s;
    width: 250px;
  }
  &__input {
    width: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 32px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 100px;
    border: 1px solid var(--basic-color-4, #7E7E7E);
    background: var(--basic-color-0, #FFF);
    min-height: 48px;
    transition: .3s;
    outline: none;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 32 32'%3E%3Cpath fill='%23111' d='m28.53 27.47-6.422-6.422a10.769 10.769 0 1 0-1.06 1.06l6.422 6.422a.75.75 0 0 0 1.06-1.06ZM4.75 14A9.25 9.25 0 1 1 14 23.25 9.261 9.261 0 0 1 4.75 14Z'/%3E%3C/svg%3E") no-repeat 16px 7px / 32px 32px;

    padding: 8px 16px 8px 58px;
    min-width: 250px;
    &::placeholder {
      color: var(--basic-color-3, #999);
    }

    &:not(:focus):placeholder-shown {
      padding: 0;
      min-width: 64px;

      + .site-search__list {
        opacity: 0;
        pointer-events: none;
      }

      &::placeholder {
        color: transparent;
      }
    }
  }
}

.site-account-item {
  &__link {
    display: flex;
    align-items: center;
    gap: 16px;
    text-decoration: none;
    padding: 4px 8px;
    transition: .3s;
    color: var(--basic-color-5, #111);

    path {
      transition: .3s;
    }

    &:hover {
      border-radius: 4px;
      background: var(--bg-color-pink, #FFF5FC);
      color: var(--primary-color-pink, #FE4DCD);

      path {
        fill: var(--primary-color-pink, #FE4DCD);
      }
    }
  }
  &__img {
    width: 24px;
  }
  &__name {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  &__left {
    color: var(--basic-color-3, #999);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
  }
}
.site-account {
  position: relative;
  padding-right: 0;
  &__list {
    position: absolute;
    border-radius: 0px 0px 4px 4px;
    background: var(--basic-color-0, #FFF);
    padding: 8px;
    width: 268px;
    right: 0;
    top: calc(100% + 16px);
    box-shadow: 1px 1px 2px 0 #ccc;

    opacity: 0;
    pointer-events: none;
    transition: .3s;

    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
  &__btn {
    display: flex;
    align-items: center;
    padding: 8px 32px;
    border-radius: 100px;
    border: 1px solid var(--basic-color-4, #7E7E7E);
    background: var(--basic-color-0, #FFF);
    gap: 10px;
    outline: none;
    transition: .3s;
    cursor: pointer;

    &:hover {
      background: var(--bg-color-pink, #FFF5FC);
    }

    &.active {
      background: var(--bg-color-pink, #FFF5FC);
      svg {
        transform: scaleY(-1);
      }
    }

    svg {
      width: 16px;
      transition: .3s;
    }
  }
  &__img {
    width: 32px;
    aspect-ratio: 1/1;
    border-radius: 50%;
  }
  &__name {
    color: var(--basic-color-5, #111);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.btn {
  border-radius: 100px;
  padding: 14px 32px;
  text-decoration: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  transition: .3s;
  display: inline-block;
  &--transparent {
    border: 1px solid var(--basic-color-4, #7E7E7E);
    background: var(--basic-color-0, #FFF);
    color: var(--basic-color-5, #111);
    &:hover {
      background: var(--bg-color-pink, #FFF5FC);
    }
  }
  &--transparent-new {
    border: 1px solid var(--basic-color-4, #7E7E7E);
    background: var(--basic-color-0, #FFF);
    color: var(--basic-color-5, #111);
    &:hover {
      background: #FF90E0;
      color: #111;
    }
  }
  &--white {
    border: 1px solid var(--basic-color-4, #FFF);
    background: var(--basic-color-0, #FFF);
    color: var(--basic-color-5, #111);
    &:hover {
      border-color: var(--primary-color-pink, #FE4DCD);
      background: var(--primary-color-pink, #FE4DCD);
    }
  }
  &--black {
    border: 1px solid var(--basic-color-5, #111);
    background: var(--basic-color-5, #111);
    color: var(--basic-color-0, #FFF);

    &:hover {
      border-color: var(--primary-color-pink, #FE4DCD);
      background: var(--primary-color-pink, #FE4DCD);
    }
  }
  &--network {
    background: transparent;
    padding: 8px 14px;
    border: 1px solid #7E7E7E;
    display: flex;

    &:hover {
      background: var(--bg-color-pink, #FFF5FC);
    }

    svg {
      width: 32px;
    }
  }
}

.burger-btn {
  display: none;
}
h2 {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
}
h1, .h1 {
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
}
p {
  color: var(--basic-color-5, #111);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
p:first-child {
  margin-top: 0;
}
p:last-child {
  margin-bottom: 0;
}
main {
  padding-top: 80px;
}
.common-header {
  background: var(--bg-color-gray, #FAFAFA);
  &__list {
    display: flex;
    align-items: center;
  }
  &__link {
    padding: 16px 8px;
    color: var(--basic-color-5, #111);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    display: block;
    line-height: 20px;
    width: 177px;
    text-decoration: none;
    transition: .3s;
    display: flex;
    align-items: center;

    span:nth-child(1) {
      width: 100%;
    }

    &:hover {
      background: #FFF5FC;
    }

    &.active {
      background: #FFF5FC;
      box-shadow: 0px 1px 0px 0px #FE4DCD;
    }
  }
  &__icon {
    width: 24px;
    margin-right: 16px;
  }
}
main {
  min-height: 75vh;
}
