/* Text translate */
.vjs-text-track-display {
  margin-top: auto;
  font-size: 1.3em;
  height: max-content;
  top: auto;
  bottom: 10em;
  pointer-events: auto;
}

.video-js.vjs-user-inactive.vjs-playing .vjs-text-track-display {
  bottom: 6em;
}

.vjs-text-track-display>div {
  margin: 0 !important;
  position: static !important;
}

.video-js .vjs-text-track-display .tooltip_content {
  display: none;
}

.video-js .vjs-text-track-display .vjs-text-track-cue {
  position: static !important;
  height: max-content !important;
  padding-top: 10px;
  background-color: rgba(17, 17, 17, 0.9) !important;
  font-size: 2em !important;
}

.video-js .vjs-text-track-display .vjs-text-track-cue:last-child{
  padding-top: 5px;
  padding-bottom: 10px;
}

.video-js .vjs-text-track-display .vjs-text-track-cue > div {
  background-color: initial !important;
  font-size: 0.93em;
}

.video-js .vjs-text-track-display .vjs-text-track-cue.vjs-text-track-cue-priority>div {
  color: #fc6 !important;
  font-size: 1.3em;
}

.video-js .vjs-text-track-display .vjs-text-track-cue .word-container {
  position: relative;
  z-index: 3;
}

.video-js .vjs-text-track-display .word-container .tooltip {
  display: none;
  position: absolute;
  width: 20em;
  font-size: 0.70em;
  bottom: 100%;
  left: calc(50% - 10em);
  padding: 1em;
  text-align: left;
  box-sizing: border-box;
  background-color: rgba(17, 17, 17, 0.9);
  border: 1px solid #212020;
  border-radius: 4px;
  color: white;
}

.video-js .vjs-text-track-display .word-container.active .tooltip {
  display: block !important;
}

.video-js .vjs-text-track-display .word-container.active {
  color: #FE4DCD;
}

.video-js .vjs-text-track-display .vjs-text-track-cue-priority .word-container.active {
  color: #fc6 !important;
  background-color: rgba(96, 132, 185, 0.83);
}

.video-js .vjs-text-track-cue .tooltip .translated_text {
  color: #FE4DCD;
  font-weight: 700;
  margin: 0 0 1em 0;
  font-size: 1.35em;
  max-width: 75%;
}

.video-js .vjs-text-track-cue .tooltip .translation {
  color: #fff;
  font-weight: bold;
}

.video-js .vjs-text-track-display .tooltip .translation_version span.text_version {
  font-size: 1.1em;
  color: #7E7E7E;
}

.video-js .vjs-text-track-display .tooltip .translation_version span.part_of_speech{
  color: #626162;
  font-size: 0.9em;
  float: right;
  margin-left: 0.5em;
}

.video-js .vjs-text-track-display .tooltip .translation_version {
  margin-top: 1.5em;
  position: relative;
  font-size: 0.85em;
}

.video-js .vjs-text-track-cue .translation_dictionary_image {
  cursor: pointer;
  font-size: 0.7em;
  margin-left: 15px;
  float: right;
  width: 3em;
  height: 2em;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.video-js .vjs-text-track-cue .translation_dictionary {
  width: 2em;
  margin-top: 0.2em;
}

.video-js .vjs-title-wrapper.has-menu .vjs-menu .vjs-menu-content .season-pane {
  max-height: 436.5px;
  position: relative;
}

.video-js .vjs-title-wrapper.has-menu .vjs-menu .vjs-menu-content span {
  display: block;
  font-size: 1.68em;
  color: inherit;
  margin-right: 0.9em;
  padding: 1em 1em 1em 3em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  text-decoration: none;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.video-js .vjs-subtitles-button>.vjs-control-text {
  display: flex;
  align-items: center;
  line-height: 2.6em;
  font-size: 1.68em;
  width: auto;
  height: 100%;
  position: relative;
  -webkit-transition: opacity 0.4s;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Season selection */

.video-js .vjs-title-wrapper.has-menu .vjs-menu {
  width: 100%;
  min-width: 42em;
  left: 0;
  color: white;
  text-align: left;
}

.vjs-title-wrapper .vjs-hidden+.vjs-title-control {
  background-color: red;
  color: white;
}

.vjs-title-wrapper.vjs-menu-button.vjs-menu-button-popup.vjs-control {
  width: max-content;
  align-items: center;
  margin-left: 9em;
  padding: 0 1em;
}

.vjs-title-wrapper.vjs-menu-button .vjs-control-text {
  clip: initial;
  width: max-content;
  height: max-content;
}

.video-js .vjs-title-wrapper .vjs-title-control .svg-container {
  width: 3.5em;
}

.video-js .vjs-menu-button-popup .vjs-menu-title {
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  color: rgb(183 183 183);
  text-transform: capitalize;
  font-size: 1.68em;
  white-space: nowrap;
  padding: 0.8em 1em;
  border-bottom: 1px solid #4c4c4c;
}

.video-js .vjs-title-wrapper.has-menu .vjs-menu .bottom-line {
  white-space: nowrap;
  border-top: 2px solid rgba(102, 107, 109, 0.5);
  overflow: hidden;
  background-color: #161515;
  font-size: 1.68em;
}

.vjs-title-wrapper.vjs-menu-button.vjs-menu-button-popup.vjs-control .vjs-menu {
  width: max-content;
  min-width: 22em;
  background-color: rgba(17, 17, 17, 0.9) ;
}


.video-js .vjs-title-wrapper.has-menu .vjs-menu .vjs-menu-content .season-pane {
  max-height: 436.5px;
  position: relative;
}

.video-js .vjs-title-wrapper.has-menu .vjs-menu .vjs-menu-content span {
  display: block;
  font-size: 1.68em;
  color: rgba(255, 255, 255, 1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  text-decoration: none;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  padding-bottom: 1em;
}


.video-js .vjs-title-wrapper.has-menu .vjs-menu .vjs-menu-content span.current {
  color: rgba(254, 77, 205, 1);
  font-weight: 700;
}

.video-js .vjs-title-wrapper.has-menu .vjs-menu .vjs-menu-content span:hover {
  background-color: #4e4f4f;
}

.video-js .vjs-title-wrapper.has-menu .vjs-menu .bottom-line {
  white-space: nowrap;
  /* border-top: 2px solid rgba(102, 107, 109, 0.5); */
  overflow: hidden;
  background-color: #161515;
  font-size: 1.68em;
}

.video-js .vjs-title-wrapper .vjs-menu-content {
  margin-block: 0.5em;
  width: calc(100% - 0.3em);
}

.video-js .vjs-title-wrapper.has-menu .vjs-menu .season-control {
  display: flex;
  justify-content: space-between;
}

.video-js .vjs-title-wrapper.has-menu .vjs-menu .bottom-line .next,
.video-js .vjs-title-wrapper.has-menu .vjs-menu .bottom-line .prev {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1em;
  opacity: 0.3;
  transition: opacity 0.5s;
}

.video-js .vjs-title-wrapper.has-menu .vjs-menu .bottom-line .next {
  margin-left: auto;
}

.video-js .vjs-title-wrapper.has-menu .vjs-menu .bottom-line .next span,
.video-js .vjs-title-wrapper.has-menu .vjs-menu .bottom-line .prev span {
  padding: 0 0.4em;
}

.video-js .vjs-title-wrapper.has-menu .vjs-menu .bottom-line svg {
  width: 20px;
}

.video-js .vjs-title-wrapper.has-menu .vjs-menu .bottom-line .next:hover,
.video-js .vjs-title-wrapper.has-menu .vjs-menu .bottom-line .prev:hover {
  opacity: 1;
}

.ps-container>.ps-scrollbar-y-rail>.ps-scrollbar-y,
.ps>.ps__scrollbar-y-rail>.ps__scrollbar-y {
  position: absolute;
  background-color: #aaa;
  border-radius: 6px;
  -webkit-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  transition: background-color 0.2s linear, height 0.2s linear, width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  transition-property: background-color, height, width, border-radius;
  transition-duration: 0.2s, 0.2s, 0.2s, 0.2s;
  transition-timing-function: linear, linear, ease-in-out, ease-in-out;
  transition-delay: 0s, 0s, 0s, 0s;
  right: 2px;
  width: 6px;
  min-height: 15px;
}

/* Language selection */

.video-js .vjs-subtitles-button .vjs-control-text  {
  padding-left: 2.3em;
  padding-right: 0.3em;
  margin: 0;
}

.video-js .vjs-subtitles-button {
  width: auto;
}

.video-js .vjs-subtitles-button .vjs-icon-placeholder:before {
  width: 1.3em;
  left: 0%;
  transform: translate(0%, -50%);
}

.video-js .vjs-subtitles-button .font-size-control {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #111111;
  margin: -2.8em -2.8em 0;
  padding: 2.4em 2.7em;
}

.video-js .vjs-subtitles-button .vjs-menu .vjs-menu-content {
  padding: 2.8em;
  overflow: hidden;
  max-height: 30em;
}

.video-js .vjs-subtitles-button.vjs-menu-button-popup .vjs-menu {
  min-width: 27em;
  left: auto;
  right: -3.6em;
}

.video-js .vjs-subtitles-button .vjs-menu-item.main-lang {
  margin-right: 2.3em;
  margin-bottom: 0;
}

.video-js .vjs-subtitles-button .divider {
  position: relative;
  left: -3em;
  clear: both;
  width: 100%;
  width: calc(100% + 6em);
  height: 1px;
  background-color: #727272;
  margin-bottom: 1em;
}

.video-js .vjs-subtitles-button .vjs-menu-content .vjs-menu-item.vjs-selected:before {
  content: '';
} 

.vjs-subtitles-button .vjs-menu li.vjs-selected .checkbox-item,
.vjs-subtitles-button .vjs-menu li:hover .checkbox-item {
  border: 1px solid #fff;
}

.vjs-subtitles-button .vjs-menu li:hover,
.vjs-subtitles-button .vjs-menu li.vjs-selected:focus {
  color: #fff;
}

.vjs-subtitles-button .vjs-menu li.vjs-menu-item:hover {
  background-color: inherit;
}

.video-js .vjs-subtitles-button .vjs-menu-item.vjs-selected .checkbox-item img {
  width: 1.2em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-js .vjs-subtitles-button .font-size-control .text {
  color: rgba(255, 255, 255, 1);
  text-align: left;
  font-size: 1.5em;
  font-weight: 600;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.video-js .vjs-subtitles-button .font-size-control .item:nth-of-type(1) {
  border-radius: 8px 0 0 8px;
}

.video-js .vjs-subtitles-button .font-size-control .item:nth-of-type(2) {
  border-radius: 0 8px 8px 0;
}

.video-js .vjs-subtitles-button .font-size-control .item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3em;
  height: auto;
  padding: 0.5em;
  border: 1px solid rgba(153, 153, 153, 1);
  cursor: pointer;
  -webkit-transition: color 0.4s, border-color 0.4s;
  transition: color 0.4s, border-color 0.4s;
  vertical-align: middle;
  position: relative;
  color: rgba(255, 255, 255, 1);
  font-size: 1.2em;
  font-weight: bold;
}

.video-js .vjs-subtitles-button .font-size-control .item:hover {
  border-color: white;
  color: white;
}

.video-js .vjs-control:focus {
  text-shadow: none;
}

.video-js .vjs-subtitles-button .vjs-menu-item {
  display: none;
  text-transform: capitalize;
  color: white;
  position: relative;
  width: 5em;
  float: left;
  padding: 0 1em 0 2em;
  margin: 0 0 1.2em 0;
  margin-bottom: 1.2em;
  text-align: left;
}

.video-js .vjs-subtitles-button .vjs-menu-item:nth-child(2n) {
  margin-right: 2.3em;
}

.video-js .vjs-subtitles-button .vjs-menu-item:nth-last-child(-n+2) {
  margin-bottom: 0;
}

.video-js .vjs-subtitles-button .vjs-menu-item sup {
  text-align: center;
  font-size: 0.8em;
  position: absolute;
  right: 0;
  top: 0;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.5em;
  border-radius: 4px;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.3);
  width: 1.5em;
  height: 1.5em;
}

.video-js .vjs-subtitles-button .vjs-menu-item:nth-of-type(2n) {
  margin-right: 0;
}

.video-js .vjs-subtitles-button .vjs-menu-item .checkbox-item {
  font-size: 1.3em;
  position: absolute;
  left: 0;
  width: 1em;
  height: 1em;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
}

/*Button next  */
.video-js .vjs-next-button {
  display: flex;
  align-items: center;
  padding-left: 1em;
  width: max-content;
}
.video-js .vjs-next-button .svg-container {
  width: 2.3em;
  margin-left: 1em;
}

.video-js .vjs-next-button>.vjs-control-text {
  line-height: 2.6em;
  font-size: 1.68em;
  width: auto;
  height: 100%;
  position: relative;
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}